import { Dispatch, SetStateAction, useMemo } from "react"
import moment from "moment/moment"
import { useTranslation } from "react-i18next"
import { IOrderWithoutReview, IReview } from "../../types/content"
import ReviewForm from "../Review/ReviewForm/ReviewForm"
import Review from "../Review/Review"
import styles from "./Reviews.module.scss"

interface Props {
  reviews: (IReview | IOrderWithoutReview)[]
  isOrder?: boolean
  setReviews: Dispatch<
    SetStateAction<{
      orders: IOrderWithoutReview[]
      reviews: IReview[]
      isOrdersEnd: boolean
      isReviewsEnd: boolean
    }>
  >
  setModalInfo: Dispatch<SetStateAction<{ chat_dialog_id: string; order_id: string }>>
}

const Reviews = ({ reviews, isOrder, setReviews, setModalInfo }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const list = useMemo(() => {
    if (!reviews || reviews.length < 1) return null
    const revArray: {
      date: string
      list: (IReview | IOrderWithoutReview)[]
    }[] = []

    reviews?.map((item) => {
      const today = moment().format("DD MMMM YYYY")
      const itemDate = moment(!isOrder && "reviewed_at" in item ? item.reviewed_at * 1000 : item.created_at).format(
        "DD MMMM YYYY",
      )
      const date = today === itemDate ? t("today") : itemDate

      const itemIndexRev = revArray.findIndex((i) => i.date === date)
      if (itemIndexRev >= 0) {
        if (isOrder) {
          revArray[itemIndexRev].list.push(item as IOrderWithoutReview)
        } else {
          revArray[itemIndexRev].list.push(item as IReview)
        }
      } else {
        revArray.push({
          date: date,
          list: [item],
        })
      }
    })

    return revArray?.map((item, index) => {
      return (
        <div key={index} className={styles.block}>
          <h4 className={styles.title}>{item.date}</h4>
          <div>
            {item.list.map((review) => {
              if (isOrder && setReviews) {
                const revA = review as IOrderWithoutReview
                return (
                  <ReviewForm
                    key={revA.id}
                    {...revA}
                    className={styles.item}
                    setReviews={setReviews}
                    setModalInfo={setModalInfo}
                  />
                )
              } else {
                const revB = review as IReview
                return (
                  <Review
                    key={revB.order_id}
                    {...revB}
                    className={styles.item}
                    setModalInfo={setModalInfo}
                    setReviews={setReviews}
                  />
                )
              }
            })}
          </div>
        </div>
      )
    })
  }, [reviews, isOrder])

  return <>{list}</>
}

export default Reviews
