import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react"
import styles from "./SearchSelect.module.scss"
import { useTranslation } from "react-i18next"
import _debounce from "lodash/debounce"
import { GetUserAvatar } from "../Assets/GetUserAvatar/GetUserAvatar"
import { CrossIcon as OurCrossIcon } from "../../icons/CrossIcon"
import clsx from "clsx"

interface Props {
  className?: string
  setSelectedVal: Dispatch<SetStateAction<any>>
  searchReq: any
  isFetching?: boolean
  isLoading?: boolean
  isUninitialized?: boolean
  dataKey: string
  selectedVal?: any
  isShortClick?: any
}

const LIMIT_REQUEST = 20

const SearchSelect: React.FC<Props> = ({
  className,
  setSelectedVal,
  searchReq,
  isFetching,
  isLoading,
  isUninitialized,
  dataKey,
  selectedVal,
  isShortClick,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const listContainerRef = useRef<HTMLDivElement>(null)
  const loaderRef = useRef<HTMLDivElement>(null)
  const [isSearchLoading, setSearchLoading] = useState<boolean>(false)
  const [isListEnd, setListEnd] = useState<boolean>(false)
  const [viewList, setViewList] = useState(false)
  const [isNotSearching, setIsNotSearching] = useState(false)
  const [viewSkeleton, setViewSkeleton] = useState(false)
  const [offset, setOffset] = useState<number>(0)
  const [searchVal, setSearchVal] = useState<string>()
  const [items, setItems] = useState<any>([])

  const fetchData = async (isStart?: boolean, str?: string) => {
    if (isListEnd) return
    setOffset((prev) => (isStart ? 0 : prev + LIMIT_REQUEST))
    await searchReq({ limit: LIMIT_REQUEST, search: str ?? "", offset: isStart ? 0 : offset }).then(({ data }: any) => {
      if (data?.aUsers) {
        setItems((prevItems: any) => {
          return !isStart && offset ? [...prevItems, ...data[dataKey]] : data[dataKey] || []
        })
        setListEnd(data.bIsEnd)
        if (data.bIsEnd) setViewSkeleton(false)
      }
    })
    setSearchLoading(false)
  }

  const debounceFn = useCallback(
    _debounce((str: string) => {
      setViewList(true)
      fetchData(true, str)
    }, 500),
    [isUninitialized],
  )

  const clearing = () => {
    setSelectedVal(undefined)
    setIsNotSearching(false)
    setSearchVal("")
    setOffset(0)
    setItems([])
    setListEnd(false)
    setViewList(false)
  }

  const list = (items: any[]) => {
    return (
      <>
        {items.map((item: any, i) => (
          <div
            className={styles["select-item"]}
            key={i}
            onClick={() => {
              if (isShortClick) {
                isShortClick(item.id, item.name, item.avatar_id)
                return
              }
              setSelectedVal(item)
              setSearchVal(item.name)
              setIsNotSearching(true)
              setViewList(false)
            }}
          >
            <GetUserAvatar avatar={item.avatar_id} name={item.name} className={styles["select-item__avatar"]} />
            <div className={styles["select-item__info"]}>
              <span className={styles["select-item__label"]}>{item.name}</span>
              {item.position && <span className={styles["select-item__position"]}>{item.position}</span>}
            </div>
          </div>
        ))}
      </>
    )
  }

  useEffect(() => {
    if (isUninitialized) return

    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0]
        if (target.isIntersecting) {
          if (isFetching || isSearchLoading) return

          void fetchData(false, searchVal ?? "")
        }
      },
      { root: listContainerRef.current },
    )

    if (loaderRef.current) {
      observer.observe(loaderRef.current)
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current)
        observer.disconnect()
      }
    }
  }, [isUninitialized, isLoading, isSearchLoading, items, isFetching, listContainerRef, searchVal])

  useEffect(() => {
    if (searchVal?.length === 0) {
      clearing()
    }
    if (searchVal && searchVal?.length < 2) return
    if (isNotSearching) {
      setIsNotSearching(false)
      return
    }
    if (searchVal) {
      setOffset(0)
      setSearchLoading(true)
      setItems([])

      setListEnd(false)
      setViewSkeleton(true)
      debounceFn(searchVal)
    }
  }, [searchVal])

  return (
    <div className={clsx(styles["select"], { [styles["select__shorted"]]: isShortClick })}>
      <div className={styles["input-container"]}>
        <input
          onChange={(e) => {
            if (e.target) setSearchVal(e.target.value)
          }}
          value={searchVal}
          placeholder={t("search")}
        />
        <div className={styles.indicatorsContainer}>
          {selectedVal && (
            <div
              className={clsx(styles["indicatorsContainer__icon-container"], styles["close-icon"])}
              onClick={() => {
                clearing()
              }}
            >
              <OurCrossIcon />
            </div>
          )}
          <div className={clsx(styles["indicatorsContainer__icon-container"])}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667C8.56581 12.6667 9.70064 12.2486 10.6038 11.5466L12.862 13.8049C13.1224 14.0652 13.5445 14.0652 13.8049 13.8049C14.0652 13.5445 14.0652 13.1224 13.8049 12.862L11.5466 10.6038C12.2486 9.70064 12.6667 8.56581 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2ZM3.33333 7.33333C3.33333 5.12419 5.12419 3.33333 7.33333 3.33333C9.54247 3.33333 11.3333 5.12419 11.3333 7.33333C11.3333 9.54247 9.54247 11.3333 7.33333 11.3333C5.12419 11.3333 3.33333 9.54247 3.33333 7.33333Z"
              />
            </svg>
          </div>
        </div>
      </div>

      {viewList && (
        <div
          className={clsx(styles["list-container"], {
            [styles["list-container__clear"]]: items?.length === 0 && !viewSkeleton,
            [styles["list-container__shorted"]]: isShortClick,
          })}
          ref={listContainerRef}
        >
          {items?.length ? (
            list(items)
          ) : (
            <>{isListEnd && !items.length && <div className={styles["not-found"]}>{t("noResults")}</div>}</>
          )}
          {!isListEnd && (
            <div ref={loaderRef} className={clsx("employees__grid", styles.grid)}>
              {(isSearchLoading || isFetching || viewSkeleton) &&
                [...Array(4)].map((_item, index) => (
                  <div key={index} className={clsx(styles.skeleton, "skeletonBlock")} />
                ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default SearchSelect
