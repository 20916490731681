import { useEffect } from "react"
import clsx from "clsx"
import TitleBack from "../TitleBack/TitleBack"
import { useTranslation } from "react-i18next"
import styles from "./Profile.module.scss"
import { StarIcon } from "../../icons/StarIcon"
import CropModal from "../CropModal/CropModal"
import { useLazyGetProfileQuery, useSetProfileAvatarMutation } from "../../redux/api/auth"
import { GetUserAvatar } from "../Assets/GetUserAvatar/GetUserAvatar"
import { useAppDispatch } from "../../hooks"
import { setProfileAvatar } from "../../redux/slice/auth"

interface Props {
  className?: string
}

const Profile = ({ className }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const dispatch = useAppDispatch()
  const [getProfileData, { isLoading: isLoadingProfile, data: profileData }] = useLazyGetProfileQuery()
  const [setAvatar, { isLoading: isLoadingAvatar }] = useSetProfileAvatarMutation()

  useEffect(() => {
    getProfileData()
  }, [])

  const setImg = async (file64: string) => {
    if (isLoadingProfile) return
    await setAvatar({ avatar: file64 })
    getProfileData()
      .unwrap()
      .then((res) => {
        dispatch(setProfileAvatar(res.data.avatar_id))
      })
  }

  const delPhotoFcn = async () => {
    if (isLoadingAvatar) return
    await setAvatar({ avatar: null })
    getProfileData()
      .unwrap()
      .then((res) => {
        dispatch(setProfileAvatar(res.data.avatar_id))
      })
  }

  return (
    <div className={styles.Profile}>
      <TitleBack title={t("userProfile")} className={styles["title-back"]} />
      <div className={styles["profile-container"]}>
        {!isLoadingProfile ? (
          <>
            <div className={styles["fio-container"]}>
              <span className={styles["fio"]}>{profileData?.data.name}</span>
              {profileData?.data.total_rating && (
                <span className={styles["rating"]}>
                  <StarIcon />
                  {profileData?.data.total_rating}
                </span>
              )}
            </div>
            <CropModal
              addClass={styles["Profile__cropModal"]}
              submitModal={setImg}
              blockType={"profile"}
              delPhotoFcn={delPhotoFcn}
              photo={profileData?.data.avatar_id}
            />
            <div className={styles["info-list"]}>
              {profileData?.data.position && (
                <div className={styles["info-list__item"]}>
                  <span className={styles["item__title"]}>{t("position")}</span>
                  <span className={styles["item__info"]}>{profileData.data.position}</span>
                </div>
              )}
              {profileData?.data.department_name && (
                <div className={styles["info-list__item"]}>
                  <span className={styles["item__title"]}>{t("department")}</span>
                  <span className={styles["item__info"]}>{profileData.data.department_name}</span>
                </div>
              )}

              {profileData?.data.manager && (
                <div className={styles["info-list__item"]}>
                  <span className={styles["item__title"]}>{t("leader")}</span>
                  <div className={styles["item__info-container"]}>
                    {profileData?.data.manager.avatar_id && (
                      <GetUserAvatar
                        avatar={profileData?.data.manager.avatar_id}
                        name={profileData?.data.manager.name}
                        size={"sm"}
                      />
                    )}

                    <span className={styles["item__info"]}>{profileData?.data.manager.name}</span>
                  </div>
                </div>
              )}
              {profileData?.data.email && (
                <div className={styles["info-list__item"]}>
                  <span className={styles["item__title"]}>Email</span>
                  <span className={styles["item__info"]}>{profileData.data.email}</span>
                </div>
              )}
              {profileData?.data.phone_number && (
                <div className={styles["info-list__item"]}>
                  <span className={styles["item__title"]}>{t("mainPhone")}</span>
                  <span className={styles["item__info"]}>{profileData.data.phone_number}</span>
                </div>
              )}
              {profileData?.data.add_phone_number && (
                <div className={styles["info-list__item"]}>
                  <span className={styles["item__title"]}>{t("additionalPhone")}</span>
                  <span className={styles["item__info"]}>{profileData.data.add_phone_number}</span>
                </div>
              )}
            </div>
            <div className={styles["additional-information"]}>{t("txtChangeInfoSupport")}</div>
          </>
        ) : (
          <div className={styles["loader-container"]}>
            <div className={styles["fio-container"]}>
              <div className={clsx("skeletonBlock", styles.loadingBlock)} />
            </div>

            <div className={styles["Profile__cropModal"]}>
              <div className={clsx("skeletonBlock", styles.loadingBlock)} />
            </div>
            <div className={styles["info-list"]}>
              {[...Array(3)].map((_item, index) => (
                <div key={index} className={clsx("skeletonBlock", styles.loadingBlock)} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Profile
